// NAV SPECIFIC //

$(document).ready(function(){

	$('.printBtn').click(function() {
		window.print();
	});

	$('.downloadBtn').click(function(e) {
		window.downloaded = true; // for testing purposes
		if (!window.Cypress) {
			location.href = $(e.currentTarget).data('href');
		}
	});

	// Menu Button Script
	$("#js-menu-button").click(function(e) {
		e.preventDefault();
		$(".c-nav__list-wrapper").slideToggle("200");
		$(".c-nav__menu-icon").toggleClass("icon-navicon");
		$(".c-nav__menu-icon").toggleClass("icon-close");
		$(".c-nav, .c-secondary-nav").toggleClass("js-menu-open");
		$(".c-nav, .c-secondary-nav").toggleClass("js-menu-closed");
	});

	// Login Form Reveal – When the nav is closed
	$(".js-menu-closed .c-nav__login-link").click(function(e) {
		e.preventDefault();
		$(".c-nav-login").removeClass("hidden");
		$(".js-main-link").addClass("hidden");
		$(".c-nav__list-wrapper").show();
		$(".c-nav__menu-icon").removeClass("icon-navicon");
		$(".c-nav__menu-icon").addClass("icon-close");
		$(".c-nav, .c-secondary-nav").addClass("js-menu-open");
		$(".c-nav, .c-secondary-nav").removeClass("js-menu-closed");
	});

	// Login Form Reveal – When the nav is already open
	$(".js-menu-open .c-nav__login-link").click(function(e) {
		e.preventDefault();
		$(".c-nav-login").removeClass("hidden");
		$(".js-main-link").addClass("hidden");
		$(".c-nav__menu-icon").addClass("icon-navicon");
		$(".c-nav__menu-icon").removeClass("icon-close");
	});

	// Login Form – Cancel Button
	$(".c-nav-login__cancel-button").click(function(e) {
		e.preventDefault();
		$(".c-nav-login").addClass("hidden");
		$(".js-main-link").removeClass("hidden");
		$(".c-nav__list-wrapper").hide();
		$(".c-nav__menu-icon").addClass("icon-navicon");
		$(".c-nav__menu-icon").removeClass("icon-close");
		$(".c-nav, .c-secondary-nav").removeClass("js-menu-open");
		$(".c-nav, .c-secondary-nav").addClass("js-menu-closed");
	});

	// Smooth Scroll
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
				scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});

	// Show Zopin on click of .c-chat-cta and. c-chat-cta text
	$('.c-chat-cta, .c-chat-cta--text').click(function() {
		$zopim(function() {
			$zopim.livechat.window.show();
		});
	});

	// Banner Close Button
	$(".c-banner__close").click(function(e) {
		e.preventDefault();
		$(".c-banner").toggle();
	});
	
	$(".c-banner__close-mobile").click(function(e) {
		e.preventDefault();
		$(".c-banner").toggle();
	});

	// Tool Tip Function
	$('[data-toggle="tooltip"]').tooltip();


	// Collapse Icon
	$(".c-collapse-arrow").click(function () {
		$(this).find('i').toggleClass("icon-angle-up");
		$(this).find('i').toggleClass("icon-angle-down");
	});
	// Converts collapse icon to button in xs-sm
	$(".c-collapse-arrow.-text-button-sm ").click(function () {
		$(this).toggleClass("-open");
	});


	// Toggle Switch
	$(".c-toggle-switch").click(function(){
		if ( $(this).hasClass('-on') || $(this).hasClass('-off') ) {
			$(this).toggleClass('-off -on')
		} else if ( $(this).hasClass('-middle') ) {
			$(this).removeClass('-middle');
			$(this).addClass('-on');
		}
	});

	// Button Group Selection
	$('.c-btn-group li').click(function(){
		$(this).siblings().children().removeClass('-selected');
		$(this).children().addClass('-selected');
	});

});






// After Resize – Run a function after window resize is complete
function afterResize(e) {
	var resizeTimer;
	$(window).on('resize', function() {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function() {
			e();
		}, 250);
	});
}

